@media screen and (max-width: 820px) {
  .home3 {
    height: 100vh;
    > div {
      height: 100vh;
    }
  }
  .contact > main {
    height: 100vh;
    width: 100%;
    border-radius: 0;
    h1 {
      text-align: center;
    }
  }
}

@media screen and (max-width: 700px) {
  // nav {
  //   > img {
  //     // display: none;
  //     height: 2rem;
  //     width: 7rem;

  //   }
  //   // > main {
  //   //   width: 100%;
  //   //   justify-content: center;
  //   //   a {
  //   //     margin: 0.5rem;
  //   //   }
  //   // }
  // }

  .home > main {
    align-items: center;
    h1 {
      font-size: 2.75rem;
    }
    p{
      font-size: 1.75rem;
    }
  }

  .home2 {
    height: 120vh;
    flex-direction: column;

    img {
      width: 70%;
    }
    > div {
      margin-top: 1rem;
      width: 100%;
      padding: 1rem;
      text-align: center;
    }
  }

  .home3 {
    height: 100vh;
    > div {
      text-align: center;

      width: 100%;
      border-radius: 0;
      > h1 {
        white-space: normal;
      }
      > p {
        padding: 3rem 1.5rem;
        font-size: 1.25rem;
      }
    }
  }

  .home4 {
    align-items: center;
    justify-content: center;
    padding: 0;
    > div {
      border-radius: 0%;
      width: 100%;
      height: unset;
    }
    > div > article > div {
      width: 90%;
      > .faq-header {
        > h3 {
          margin-right: 1rem;
        }
      }
    }
  }
  // .home4 {
  //   padding: 1rem;

  //   > div {
  //     border-radius: 0;
  //     width: 100%;
  //     padding: 1rem;
  //   }

  //   > div > article > div {
  //     width: 100%;
  //   }
  // }

  .home5 {
    padding: 3rem 0;
    flex-direction: column;
    height: auto;
    > div {
      margin-top: 1rem;
    }
  }

  .home6 {
    flex-direction: column;
    text-align: center;
    gap: 0;

    > div {
      > h1 {
        font-size: 2.5rem;
      }
      > div {
        align-items: center;
        > i {
          margin-top: 0.5rem;
        }
      }
    }
  }

  .contact {
    > main {
      padding: 1rem;

      h1 {
        margin: 1rem;
      }
      p {
        max-width: 100%;
        text-align: center;
      }
      > form {
        margin: 10% 0 0 0;
        > div {
          width: 100%;
        }
      }
    }
  }

  footer {
    // height: 90vh;
    // grid-template-columns: 1fr;
    // // padding: 6rem;
    // > div {
    //   // > img {
    //   //   height: 7rem;
    //   //   width: 20rem;
    //   // }
    //   // > h1,
    //   // p {
    //   //   padding: 0%;
    //   // }
    //   align-items: center;
    //   text-align: center;
    //   > h5 {
    //     margin: 2rem 0 0.5rem 0;
    //   }
    // }
    height: auto;
    .footer-content {
      flex-direction: column;
      align-items: center;
      padding: 0;

      .footer-section {
        margin-bottom: 1rem;

        &.logo-section {
          img {
            height: 7rem;
            width: 20rem;
          }
        }

        &.follow-section {
          margin-top: 2.5rem;

          .social-links {
            a {
              font-size: 1rem;
            }
          }
        }
      }
    }

    .footerdown {
      padding: 1rem;
      p {
        text-align: center;
        font-size: 0.875rem;
        line-height: 1.2;
        > span {
          display: block;
        }
      }
    }
  }
}
