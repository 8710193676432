// @import "colours";
// nav {
//   width: 100%;
//   padding: 1rem;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   position: sticky;
//   background-color: $first;
//   top: 0%;
//   z-index: 10;

//   > img {
//     height: 5rem;
//     width: 16rem;
//   }

//   > main {
//     width: 70%;
//     display: flex;
//     justify-content: flex-end;

//     > a {
//       color: $dark;
//       margin: 1rem;

//       &:hover {
//         color: $third;
//       }
//     }
//   }
// }

@import "colours";

nav {
  width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  background-color: $first;
  top: 0%;
  z-index: 10;

  > img {
    height: 5rem;
    width: 16rem;
    cursor: pointer;
  }

  .burger-menu {
    display: none;
    font-size: 2rem;
    cursor: pointer;
  }

  > main {
    width: 70%;
    display: flex;
    justify-content: flex-end;

    > a {
      font: 400 1rem $text1;
      color: $dark;
      margin: 1rem;

      &:hover {
        color: $third;
      }
    }
  }
}

@media screen and (max-width: 700px) {
  nav {
    > img {
      height: 2.5rem;
      width: 7rem;
    }

    .burger-menu {
      // display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 1rem;
    }

    > main {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: auto;
      background-color: $first;
      flex-direction: column;
      align-items: center;
      display: none;
      justify-content: center;

      &.menu-open {
        display: flex;
      }

      > a {
        margin: 1rem 0;
      }
    }
  }
}
