@import "colours";

.contact {
  width: 100%;
  min-height: 100vh;
  background-color: $second;
  display: flex;
  justify-content: center;
  align-items: center;

  > main {
    background-color: $first;
    height: 40rem;
    width: 70%;
    padding: 3rem;
    border-radius: 0 200px 200px 0;

    > h1 {
      font: 400 2rem $text1;
      text-shadow: 0 1px 0 $third, 0 2px 0 $third, 0 3px 0 $third,
          0 4px 0 $third, 0 1px 0 $third, 0 8px 1px rgba(0, 0, 0, 0.1),
          0 1px 2px rgba(0, 0, 0, 0.1), 0 0.5px 1px rgba(0, 0, 0, 0.3),
          0 1px 2px rgba(0, 0, 0, 0.2), 0 3px 3px rgba(0, 0, 0, 0.25),
          0 3px 3px rgba(0, 0, 0, 0.2), 0 5px 5px rgba(0, 0, 0, 0.15);
    }

    > p {
      font: 700 1rem $text2;
      margin: 1.5rem 0 0 1rem;
      max-width: 80%;
    }

    > form {
      margin: 2.5rem 5rem 5rem 5rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > div {
        height: 3rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 60%;
        margin: 0.5rem;

        > label {
          font: 400 1.2rem $text1;
          width: 20%;
          margin: 0 1rem;
        }
        > input,
        textarea {
          align-self: stretch;
          width: 80%;
          padding: 1rem;
          border-radius: 5px;
          border: 1px solid rgba(123, 123, 123, 0.6);
          outline: none;
        }
      }

      > button {
        margin: 3rem 0;
        padding: 0.7rem;
        border: none;
        border-radius: 5px;
        background-color: $second;
        color: $first;
        width: 200px;
        font: 500 1rem $text1;
        &:hover {
          opacity: 0.9;
          color: $second;
          background-color: $third;
        }
      }
    }
  }
}
