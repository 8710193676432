// @import "colours";

// footer {
//   background-color: $first;
//   color: $dark;
//   height: auto;
//   width: 100%;
//   padding: 2rem;
//   display: grid;
//   grid-template-columns: 4fr 1fr;
//   border-top: 5px solid $second;

//   > div {
//     border: 1px solid red;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     >img{
//       height: 8rem;
//       width: 25rem;
//     }
//     > h1 {
//       font: 700 1rem $text1;
//       text-transform: uppercase;
//       color: $third;
//       strong {
//         font: 700 2rem $text1;
//       }
//     }
//     > p {
//       font: 700 1rem $text2;
//     }
//     > h5 {
//       font: 400 1rem $text1;
//       margin: 1.25rem;
//       text-align: center;
//     }
//     > div {
//       display: flex;
//       flex-direction: column;
//       align-items: center;
//       a {
//         color: $dark;
//         font: 900 1.25rem $text2;
//         margin: 0.2rem;
//         &:hover {
//           color: $third;
//         }
//       }
//     }
//   }

// }

@import "colours";

footer {
  background-color: $first;
  color: $dark;
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  // align-items: center;
  border-top: 5px solid $second;

  .footer-content {
    display: flex;
    // width: 100%;
    justify-content: space-between;
    padding: 0 5rem 0 0;
    margin-bottom: 2rem;

    .footer-section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;

      &.logo-section {
        img {
          height: 8rem;
          width: 25rem;
        }
      }

      &.follow-section {
        h5 {
          font: 400 1rem $text1;
          margin: 1.25rem 1.25rem 1.25rem 0;
          text-align: center;
        }

        .social-links {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem; // Add space between links

          a {
            color: $dark;
            font: 900 1.25rem $text2;
            display: flex;
            align-items: center;

            &:hover {
              color: $third;
            }

            svg {
              margin-right: 0.5rem;
            }
          }
        }
      }
    }
  }

  .footerdown {
    width: 100%;
    text-align: center;
    border-top: 1px solid $dark;
    padding-top: 1rem;
  }
}

