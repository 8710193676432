// .services {
//   display: flex;
//   flex-direction: column;
//   gap: 2rem;
//   background-color: #050e29;
//   padding: 0 1.5rem 10rem 1.5rem;

//   > h1 {
//     align-self: center;
//     border-bottom: 2px solid white;
//     color: white;
//     font-weight: 900;
//     font-size: 2.5rem;
//     text-shadow:  0 0.5px 0 #39ff14, 0 1px 0 #39ff14, 0 1.5px 0 #39ff14,
//     0 2px 0 #39ff14, 0 2.5px 0 #39ff14, 0 8px 1px rgba(255, 255, 255, 0.1),
//       0 1px 2px rgba(255, 255, 255, 0.1), 0 0.5px 1px rgba(255, 255, 255, 0.5),
//       0 1px 2px rgba(255, 255, 255, 0.2), 0 3px 3px rgba(255, 255, 255, 0.5),
//       0 3px 3px rgba(255, 255, 255, 0.2), 0 5px 5px rgba(255, 255, 255, 0.5);
//   }

//   .service-item {
//     background-color: rgb(255, 255, 255);
//     display: flex;
//     align-items: center;
//     justify-content: space-between;
//     gap: 2rem;
//     padding: 2rem;
//     // border: 1px solid #ddd;
//     border-radius: 8px;
//     transition: transform 0.3s;

//     &:hover {
//       transform: translateY(-5px);
//     }

//     &.even {
//       flex-direction: row-reverse;
//     }

//     .service-image {
//       flex: 1;
//       max-width: 45%;
//       max-height: 25rem;
//       height: auto;
//       border-radius: 8px;
//     }

//     .text {
//       flex: 1;

//       h1 {
//         font-family: "Irish Grover", cursive; /* Apply Irish Grover font to h1 */
//         font-size: 1.5rem; /* Example font size */
//         margin-bottom: 0.5rem;
//         font-weight: 900;
//         color: #39ff14;
//         text-shadow: 0 1px 0 #050e29, 0 2px 0 #050e29, 0 3px 0 #050e29,
//           0 4px 0 #050e29, 0 1px 0 #050e29, 0 8px 1px rgba(0, 0, 0, 0.1),
//           0 1px 2px rgba(0, 0, 0, 0.1), 0 0.5px 1px rgba(0, 0, 0, 0.3),
//           0 1px 2px rgba(0, 0, 0, 0.2), 0 3px 3px rgba(0, 0, 0, 0.25),
//           0 3px 3px rgba(0, 0, 0, 0.2), 0 5px 5px rgba(0, 0, 0, 0.15);
//       }

//       hr {
//         margin: 0.5rem 0;
//       }

//       p {
//         font-family: "Life Savers", cursive; /* Apply Life Savers font to p */
//         font-size: 1.1rem;
//         margin-bottom: 1rem; /* Example margin */
//         font-weight: 700;
//         color: #050e2a;
//       }
//     }
//   }
// }

// // Media queries for responsiveness
// @media (max-width: 768px) {
//   .services {
//     padding-bottom: 2rem;
//     > h1 {
//       padding-top: 1.5rem;
//     }
//     .service-item {
//       flex-direction: column;
//       text-align: center;

//       &.even {
//         flex-direction: column;
//       }

//       .service-image,
//       .text {
//         max-width: 100%;
//       }
//     }
//   }
// }

.services {
  display: flex;
  flex-direction: column;
  gap: 6rem;
  background-color: #050e29;
  padding: 0 3rem 10rem 3rem;
}

.services > h1 {
  align-self: center;
  border-bottom: 2px solid white;
  color: white;
  font-weight: 400;
  font-size: 2.5rem;
  text-shadow: 0 0.5px 0 #39ff14, 0 1px 0 #39ff14, 0 1.5px 0 #39ff14,
    0 2px 0 #39ff14, 0 2.5px 0 #39ff14, 0 8px 1px rgba(255, 255, 255, 0.1),
    0 1px 2px rgba(255, 255, 255, 0.1), 0 0.5px 1px rgba(255, 255, 255, 0.5),
    0 1px 2px rgba(255, 255, 255, 0.2), 0 3px 3px rgba(255, 255, 255, 0.5),
    0 3px 3px rgba(255, 255, 255, 0.2), 0 5px 5px rgba(255, 255, 255, 0.5);
}

.service-item {
  background: url("../assets/Qualities_img_background.jpg") center/cover
    no-repeat;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem;
  border-radius: 8px;
  transition: transform 0.3s;
  overflow: hidden;
  animation: lighting 2s infinite;

  &:hover {
    transform: translateY(-5px);
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: inherit;
    filter: blur(10px);
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.199);
    z-index: 2;
  }

  .service-image,
  .text {
    position: relative;
    z-index: 3;
  }

  &.even {
    flex-direction: row-reverse;
  }

  .service-image {
    flex: 1;
    max-width: 45%;
    max-height: 25rem;
    height: auto;
    border-radius: 8px;
  }

  .text {
    flex: 1;

    h1 {
      font-family: "Irish Grover", cursive;
      font-size: 1.8rem;
      margin-bottom: 0.5rem;
      font-weight: 400;
      color: #39ff14;
      text-shadow: 0 1px 0 #050e29, 0 2px 0 #050e29, 0 3px 0 #050e29,
        0 4px 0 #050e29, 0 1px 0 #050e29, 0 8px 1px rgba(0, 0, 0, 0.1),
        0 1px 2px rgba(0, 0, 0, 0.1), 0 0.5px 1px rgba(0, 0, 0, 0.3),
        0 1px 2px rgba(0, 0, 0, 0.2), 0 3px 3px rgba(0, 0, 0, 0.25),
        0 3px 3px rgba(0, 0, 0, 0.2), 0 5px 5px rgba(0, 0, 0, 0.15);
    }

    hr {
      margin: 0.5rem 0;
    }

    p {
      font-family: "Life Savers", cursive;
      font-size: 1.15rem;
      margin-bottom: 1rem;
      font-weight:900;
      color: #ffffff;
      text-shadow: 0 0.5px 0 #050e29, 0 1px 0 #050e29, 0 1.5px 0 #050e29,
      0 4px 0 #050e29, 0 2px 0 #050e29, 0 8px 1px rgba(0, 0, 0, 0.1),
      0 1px 2px rgba(0, 0, 0, 0.1), 0 0.5px 1px rgba(0, 0, 0, 0.2),
      0 1px 2px rgba(0, 0, 0, 0.3), 0 3px 3px rgba(0, 0, 0, 0.4),
      0 3px 3px rgba(0, 0, 0, 0.5), 0 5px 5px rgba(0, 0, 0, 0.6);
    }
  }
}

@keyframes lighting {
  0%,
  100% {
    box-shadow: 0 0 10px #39ff14, 0 0 20px #39ff14, 0 0 30px #39ff14,
      0 0 40px #39ff14;
  }
  50% {
    box-shadow: 0 0 20px #39ff14, 0 0 30px #39ff14, 0 0 40px #39ff14,
      0 0 50px #39ff14;
  }
}

// Media queries for responsiveness
@media (max-width: 768px) {
  .services {
    padding-bottom: 2rem;

    > h1 {
      padding-top: 1.5rem;
    }

    .service-item {
      flex-direction: column;
      text-align: center;

 

      &.even {
        flex-direction: column;
      }

      .service-image,
      .text {
        max-width: 100%;
      }
    }
  }
}
