@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Irish+Grover&family=Life+Savers:wght@400;700&display=swap");
* {
  padding: 0%;
  margin: 0%;
  // font-family: "Roboto", sans-serif;
  font-family: "Irish Grover", cursive;
  // font-family: "Life Savers", cursive;
  box-sizing: border-box;
  text-decoration: none;
  scroll-behavior: smooth;
}
