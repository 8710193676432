@import "colours";

.home {
  width: 100%;
  height: 100vh;
  background-color: $second;
  border-radius: 0 0 200px 200px;

  > main {
    position: absolute;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    align-items:flex-end;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 2rem;

    h1 {
      font: 900 3.8rem $text1;
    }
    p {
      font: 900 2.5rem $text2;
      text-transform: none;
      word-spacing:0.25rem;
    }
    h1,
    p {
      position: relative;
      color: aliceblue;
      text-shadow: 0 1px 0 $second, 0 2px 0 $second, 0 3px 0 $second,
        0 4px 0 $second, 0 5px 0 $second, 0 10px 2px rgba(0, 0, 0, 0.1),
        0 3px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.3),
        0 4px 7px rgba(0, 0, 0, 0.2), 0 5px 10px rgba(0, 0, 0, 0.25),
        0 15px 15px rgba(0, 0, 0, 0.2), 0 20px 20px rgba(0, 0, 0, 0.15);
    }
  }
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: no-repeat center/auto url("../assets/Banner_Bg - OP10.webp");
    background-size: 100% 100%;
    filter: blur(1px);
    // border-radius: 0 0 300px 300px;
  }
}

// .home2 {
//   width: 100%;
//   height: 100vh;
//   background-color: $second;
//   padding: 500px 0;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-top: -200px;

//   > img {
//     width: 30%;
//     border-radius: 50%;

//     animation: imgUp 0.7s linear infinite alternate;
//     background: linear-gradient(
//       145deg,
//       rgba(255, 255, 255, 1),
//       rgba(255, 255, 255, 0.9)
//     );
//     box-shadow: 0 0 20px rgba(255, 255, 255, 0.8),
//       0 0 40px rgba(255, 255, 255, 0.6), 0 0 60px rgba(255, 255, 255, 0.5);
//     display: inline-block;
//   }

//   > div {
//     width: 50%;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding: 5rem;
//     height: 100vh;

//     p {
//       color: $first;
//       letter-spacing: 2px;
//       word-spacing: 5px;
//       font: 100 1.2rem $text2;
//     }
//   }
// }

// @keyframes imgUp {
//   to {
//     transform: translateY(-10px);
//   }
// }

.home2 {
  width: 100%;
  height: 100vh;
  background-color: $second;
  padding: 500px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -200px;

  > img {
    width: 30%;
    border-radius: 50%;
    animation: imgUp 0.7s linear infinite alternate,
               lightingEffect 2s infinite;
    background: linear-gradient(
      145deg,
      rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0.9)
    );
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8),
                0 0 40px rgba(255, 255, 255, 0.6),
                0 0 60px rgba(255, 255, 255, 0.5);
    display: inline-block;
  }

  > div {
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    height: 100vh;

    p {
      color: $first;
      letter-spacing: 2px;
      word-spacing: 5px;
      font: 100 1.2rem $text2;
    }
  }
}

@keyframes imgUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-10px);
  }
}

@keyframes lightingEffect {
  0%, 100% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8),
                0 0 40px rgba(255, 255, 255, 0.6),
                0 0 60px rgba(255, 255, 255, 0.5);
  }
  50% {
    box-shadow: 0 0 30px rgba(255, 255, 255, 0.9),
                0 0 50px rgba(255, 255, 255, 0.7),
                0 0 70px rgba(255, 255, 255, 0.6);
  }
}


.home3 {
  width: 100%;
  height: 100vh;
  background-color: $second;
  margin-top: -200px;
  display: flex;

  > div {
    width: 70%;
    height: 75vh;
    background-color: $first;
    border-radius: 0 200px 200px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;

    > h1 {
      // text-transform: uppercase;
      white-space: nowrap;
      border-bottom: 2px solid $second;
      color: $second;
      font: 400 2rem $text1;
      text-shadow: 0 1px 0 $third, 0 2px 0 $third, 0 3px 0 $third,
        0 4px 0 $third, 0 4px 0 $third, 0 8px 1px rgba(0, 0, 0, 0.1),
        0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.3),
        0 1px 3px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.25),
        0 5px 5px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.15);
    }

    > p {
      padding: 5rem;
      letter-spacing: 1px;
      word-spacing: 5px;
      line-height: 170%;
      font: italic 700 1.2rem $text2;
      color: $second;
    }
  }
}

.home4 {
  width: 100%;
  height: auto;
  background-color: $second;
  display: flex;
  justify-content: flex-end;
  padding: 10rem 0;

  > div {
    background-color: #04d9ff;
    border-radius: 200px 0 0 200px;
    // height: 70vh;
    width: 80%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > h1 {
      font: 400 2rem $text1;
      color: $second;
      text-transform: uppercase;
      // width: 140px;
      border-bottom: 2px solid $second;
      padding: 0.5rem;
      text-shadow: 0 1px 0 $first, 0 2px 0 $first, 0 3px 0 $first,
        0 4px 0 $first, 0 4px 0 $third, 0 8px 1px rgba(0, 0, 0, 0.1),
        0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.3),
        0 1px 3px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.25),
        0 5px 5px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.15);
    }

    > article {
      display: flex;
      flex-direction: column;
      margin: 2rem 0;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      > div {
        animation: imgUp 0.7s linear infinite alternate;
        margin: 1rem;
        height: auto;
        width: 40rem;
        background-color: $first;
        border-radius: 32px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: $second;
        padding: 1rem;
        cursor: pointer;
        transition: all 0.5s;

        &:hover {
          filter: invert(1);
        }
        .faq-header {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: 95%;

          h3 {
            font: 700 1.2rem $text1;
            margin: 0;
            text-align: start;
            color: $second;
          }

          button {
            background: none;
            border: none;
            font: 700 1.2rem $text1;
            cursor: pointer;
          }
        }
        p {
          font: 700 1rem $text2;
          margin-top: 0.5rem;
          max-width: 80%;
        }
      }
    }
  }
}

// .home5 {
//   width: 100%;
//   height: 50vh;
//   background-color: $first;
//   // margin-top: 200px;
//   display: flex;
//   color: $second;
//   align-items: center;
//   justify-content: center;

//   > div {
//     padding: 2rem;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     text-align: center;

//     p {
//       font: 500 1rem $text1;
//       margin-top: 1.5rem;
//       max-width: 80%;
//     }
//   }
// }

.home5 {
  width: 100%;
  height: 50vh;
  background-image: url("../assets/Qualities_img_background.jpg"); /* Replace with the actual path to your image */
  background-size: cover; /* Ensure the image covers the entire section */
  background-position: center; /* Center the image */
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative; /* Necessary for the overlay */
  color: $second; /* Set default text color */

  /* Create a foggy/blur effect */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(
      73,
      70,
      56,
      0.7
    ); /* Adjust opacity for desired foggy effect */
    backdrop-filter: blur(5px); /* Apply blur effect */
    z-index: 1; /* Place it behind the content */
  }

  > div {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    position: relative; /* Ensure content is on top of the overlay */
    z-index: 2; /* Ensure content is above the overlay */

    > div {
      background-color: $first;
      border-radius: 50%;
      padding: 1rem;
      margin-bottom: 1rem;
      font-size: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $second;
    }
    h1 {
      font: 400 2.5rem $text1;
      color: $third; /* Heading color */
      text-shadow: 0 1px 0 $second, 0 2px 0 $second, 0 3px 0 $second,
        0 4px 0 $second, 0 4px 0 $second, 0 8px 1px rgba(0, 0, 0, 0.1),
        0 1px 3px rgba(0, 0, 0, 0.1), 0 1px 2px rgba(0, 0, 0, 0.3),
        0 1px 3px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.25),
        0 5px 5px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.15);
    }

    p {
      font: 700 1rem $text2;
      margin-top: 1.5rem;
      max-width: 95%;
      color: $first; /* Content color */
    }
  }
}

.home6 {
  display: flex;
  background-color: $second;
  color: $first;
  justify-content: space-between;
  gap: 3rem;
  padding: 0 1rem;
  border-radius: 10px;
  margin: 0.25rem;

  > div {
    padding: 1rem;
    display: flex;
    flex-direction: column;

    > h1 {
      font: 400 2.5rem $text1;
      white-space: nowrap;
      border-bottom: 2px solid $first;
      text-shadow: 0 0.5px 0 $third, 0 1px 0 $third, 0 1.5px 0 $third,
        0 2px 0 $third, 0 2.5px 0 $third, 0 8px 1px rgba(255, 255, 255, 0.1),
        0 1px 2px rgba(255, 255, 255, 0.1), 0 0.5px 1px rgba(255, 255, 255, 0.5),
        0 1px 2px rgba(255, 255, 255, 0.2), 0 3px 3px rgba(255, 255, 255, 0.5),
        0 3px 3px rgba(255, 255, 255, 0.2), 0 5px 5px rgba(255, 255, 255, 0.5);
    }
    > p {
      display: -webkit-box;
      // -webkit-line-clamp: 3; /* Limit to 3 lines */
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font: 400 1.2rem $text2;
    }

    > div {
      margin-top: 1.5rem;
      display: flex;
      flex-direction: column;
      > a {
        background-color: $first;
        width: fit-content;
        padding: 0.5rem 1.25rem;
        border-radius: 10px;
        font: 500 1rem $text1;
        cursor: pointer;

        &:hover {
          color: $second;
          background-color: $third;
        }
      }

      > i {
        margin: 0.25rem;
        font: 700 0.7em $text2;
      }
    }
  }
}

//         .faq-header {
//           display: flex;
//           justify-content: space-between;
//           align-items: center;
//           width: 100%;

//           h3 {
//             font: 700 1.2rem $text2;
//             margin: 0;
//           }

//           button {
//             background: none;
//             border: none;
//             font-size: 1.5rem;
//             cursor: pointer;
//           }
//         }

//         p {
//           font: 500 1rem $text1;
//           margin-top: 0.5rem;
//         }
//       }
//     }
//   }
// }
